import React from 'react';

const SingleTeamMember = (props) => {
  return (
    <article className="">
        <div className="single-team-member">
            <figure className="img-box">
                <div className="overlay"></div>
                <div className="inner-box">
                    <ul className="social">
                        <li><a href={props.facebook} target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href={props.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a></li>
                        <li><a href={props.instagram} target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
                <img src={props.memberImage} alt={props.memberImageAlt} className='w-100 rounded-circle' />
            </figure>
            <div className="author-info center">
                <h4>{props.memberName}</h4>
                <p>{props.memberDesignation}</p>
            </div>
        </div>
    </article> 
  )
}

export default SingleTeamMember