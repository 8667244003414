import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <section className="footer_section">
        <div className="upper-footer">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center upper-footer-text">
                    <h4 className='text-white'>Join Our Mission to Improve a Child's Feature and Our Planet.</h4>
                    <Link to="/contact" className="custom-btn btn-footer-contact">Contact Now</Link>
                </div>
            </div>
        </div>
        <footer className="main-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <Link to="/">
                            <img src="/uploads/logo_new.png" alt="Footer Logo" className='w-100' />
                        </Link>
                        <div className="footer-common">
                            <p className='about_footer'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reiciendis officia, tempore maiores mollitia dolorum debitis asperiores consectetur autem illo sapiente accusamus.</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h4 className='text-center'>Facebook Timeline</h4>
                        <div className="footer-common">
                            <div className="fb-page" data-href="https://www.facebook.com/ngodudhevate" data-tabs="timeline" data-width="" data-height="250" data-small-header="true" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="true"><blockquote cite="https://www.facebook.com/ngodudhevate" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ngodudhevate">Dudhe Vate দুধে ভাতে</a></blockquote></div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <h4 className='text-center'>Get in touch</h4>
                        <div className="footer-common">
                            <div className="footer-contact-list">
                                <ul>
                                    <li><i className="fas fa-location-arrow"> </i><strong>Address:</strong> Holding No.:- 782(665) Nabajiban, Birati, North Dumdum, Kolkata- 51</li>
                                    <li><i className="fas fa-phone"></i> <strong>Phone:</strong> <a href="tel: 9836880080"> 9836880080</a></li>
                                    <li><i className="fas fa-envelope"></i> <strong>Email:</strong> <a href="mailto:supportus@dudhevate.org">supportus@dudhevate.org</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-3">
                        <h4 className='text-center'>Find in Map</h4>
                        <div className="footer-common">
                            <iframe title='google_map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.467858338559!2d88.4337951650406!3d22.67362093513081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89ee3663cdeb7%3A0x9cc4cdf70bba0e91!2sNaba%20Jiban%2C%20Birati%2C%20Kolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1676212941898!5m2!1sen!2sin" height="250" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <div className="bottom-footer py-3 d-flex align-items-center justify-content-center">
            <p className='m-0'><span>Copyrights &copy; {new Date().getFullYear()}</span> All Rights Reserved by Dudhebhate | Powered by <span>DEM Webs India</span></p>
        </div>
    </section>
  )
}

export default Footer