import React from 'react';
import SingleTeamMember from './SingleTeamMember';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

const OurTeam = (props) => {
  const options = {
    margin: 8,
    responsiveClass: true,
    autoplayHoverPause: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout:2000,
    loop:true,
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 4,

        }
    },
  };
  return (
    <section className="our_team_section" style={{backgroundColor:props.background}}>
        <div className="container">
            <h1>our <span>team</span></h1>
            <p className='section_tagline'>We are humanity/ non-profit/ fundraising/ NGO organizations. Our humanity activities are taken place around the world,let contribute to them with us by your hand to be a better life.</p>

            <OwlCarousel {...options} className="owl-theme" >  
              <SingleTeamMember memberImage="/uploads/victor_bhattacharya.jpg" memberImageAlt="VICTOR BHATTACHARYA" memberName="VICTOR BHATTACHARYA" memberDesignation="PRESIDENT" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />
              
              <SingleTeamMember memberImage="/uploads/dr_anirban_mitra.jpg" memberImageAlt="Dr. ANIRBAN MITRA " memberName="Dr. ANIRBAN MITRA" memberDesignation="VICE-PRESIDENT" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />
              
              <SingleTeamMember memberImage="/uploads/ayan_dey.jpg" memberImageAlt="AYAN DEY" memberName="AYAN DEY" memberDesignation="SECRETARY" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />

              <SingleTeamMember memberImage="/uploads/anupam_biswas.jpg" memberImageAlt="ANUPAM BISWAS" memberName="ANUPAM BISWAS" memberDesignation="TREASURER" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />

              <SingleTeamMember memberImage="/uploads/sayari_ghatak.jpg" memberImageAlt="SAYERI GHATAK" memberName="SAYERI GHATAK" memberDesignation="ASSISTANT SECRETARY" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />
              
              <SingleTeamMember memberImage="/uploads/pallabi_das.jpg" memberImageAlt="PALLABI DAS" memberName="PALLABI DAS" memberDesignation="MEMBER" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />

              <SingleTeamMember memberImage="/uploads/abhisikta_singharoy.jpg" memberImageAlt="ABHISHIKTA SINGHAROY" memberName="ABHISHIKTA SINGHAROY" memberDesignation="MEMBER" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />

              <SingleTeamMember memberImage="/uploads/dr_tushar_ghosh.jpg" memberImageAlt="Dr. TUSHAR GHOSH" memberName="Dr. TUSHAR GHOSH" memberDesignation="MEMBER" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />

              <SingleTeamMember memberImage="/uploads/lokenath_shaw.jpg" memberImageAlt="LOKENATH SHAW" memberName="LOKENATH SHAW" memberDesignation="MEMBER" facebook="https://www.facebook.com/" instagram="https://www.instagram.com/" twitter="https://twitter.com/home" />
            </OwlCarousel>
        </div>
    </section>
  )
}

export default OurTeam